<template>
  <div class="custom-cursor">
    <div id="cursor-big" class="custom-cursor__ball custom-cursor__ball--big"></div>
  </div>
</template>

<script>
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { gsap } from "gsap";

export default {
  name: "CustomCursor",
  mounted () {
    gsap.registerPlugin(ScrollTrigger);
    
    const cursorBig = document.getElementById('cursor-big'),
          links = document.getElementsByTagName('a'),
          withClassHover = document.getElementsByClassName("link"),
          withHover = [...links, ...withClassHover];

      // Event Listeners
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mousedown", onMouseHover);
      document.addEventListener("mouseup", onMouseHoverOut);
      document.addEventListener("mouseenter", () => {
        cursorBig.style.opacity = 1;
      });
      document.addEventListener("mouseleave", () => {
        cursorBig.style.opacity = 0;
      });
      withHover.forEach((element) => {
        element.addEventListener("mouseover", onMouseHover);
        element.addEventListener("mouseout", onMouseHoverOut);
      })

      // Event Handlers
      function onMouseMove(e) {
        gsap.to(cursorBig, 0.4, {
          x: e.clientX - 0,
          y: e.clientY - 0
        });
      }
      function onMouseHover() {
        gsap.to(cursorBig, 0.3, {
          scale: 5,
          background: '#FAECE9',
        });
      }
      function onMouseHoverOut() {
        gsap.to(cursorBig, 0.3, {
          scale: 1,
          background: '#424242',
        });
      }
    }
  };
</script>


<style lang="scss">

  // * { cursor: none; }

  .custom-cursor__ball {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
  }

  .custom-cursor__ball--big {
    content: "";
    width: 15px;
    height: 15px;
    background: #424242;
    border-radius: 50%;
  }
  
</style>
