<template>
  <div class="flex flex-col items-center justify-center h-screen max-h-screen p-12 text-xl leading-6 min-h-scree xl:hidden">
    <h1 class="text-6xl font-semibold text-center">I'm soooo sorry <span class="text-4xl">😇</span></h1>
    <p class="pt-4 text-center">but this experience is visible only on desktop at the moment :)</p>
    <div class="fixed left-0 flex justify-center w-screen h-screen top-2/4 -z-10">
      <svg width="943" height="872" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M943 436c0 240.796-211.098 436-471.5 436S0 676.796 0 436 211.098 0 471.5 0 943 195.204 943 436z" fill="url(#paint0_linear_534_3)"/><defs><linearGradient id="paint0_linear_534_3" x1="471.5" x2="472.491" y2="660.963" gradientUnits="userSpaceOnUse"><stop stop-color="#FAECE9"/><stop offset="1" stop-color="#FAF5F4" stop-opacity="0"/></linearGradient></defs></svg>
    </div>
  </div>
  <div class="hidden xl:block">
    <CustomCursor/>
    <div
      id="nav"
      class="absolute top-0 left-0 z-30 w-full">
      <div class="container flex items-baseline justify-between py-6 mx-auto text-xl font-medium text-gray-900 md:text-2xl">
        <router-link to="/" class="link">
          Silviaemme
        </router-link>
        <router-link to="/about" class="flex items-center link">
          About &nbsp;
          <svg class="mt-1" width="24" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 7h22m0 0s-4-1.5-6.177-6M22 7s-4 1.5-6.177 6" stroke="#424242" stroke-width=".8"/></svg>
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
  import CustomCursor from './components/CustomCursor.vue';
  export default {
    components: {
      CustomCursor,
    },
  }
</script>

<style>
  #app {
    font-family: "Darker Grotesque", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.4px;
  }
</style>


