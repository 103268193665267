<template>
  <footer class="relative z-30 flex items-center py-56 text-gray-900">
    <div>
      <div class="pb-12">
        <p class="pb-4 text-4xl font-semibold md:text-5xl lg:text-7xl">Let's connect! ✨</p>
        <p class="text-2xl font-normal">Please don’t be shy, I'd love to hear from you :)</p>
      </div>
      <div class="flex items-center space-x-8 text-xl font-medium text-gray-900 md:text-2xl">
        <a class="transition duration-200 border-b-2 border-gray-500 border-dotted link hover:border-transparent" target="_blank" href="mailto:silvia.mastroianni97@gmail.com">Drop an email</a>
        <span>—</span>
        <a class="link" href="/assets/img/silvia-mastroianni-resume.pdf">Resume</a>
        <a class="link" target="_blank" href="https://www.linkedin.com/in/silvia-mastroianni">LinkedIn</a>
        <a class="link" target="_blank" href="https://dribbble.com/silviaemme">Dribbble</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style>

</style>