<template>
  <CustomCursor/>
  <div id="circle" class="fixed left-0 flex justify-center w-screen h-screen top-2/4 -z-10">
    <svg width="943" height="872" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="471.5" cy="436" rx="471.5" ry="436" fill="url(#paint0_linear_415_14)"/><defs><linearGradient id="paint0_linear_415_14" x1="471.5" x2="472.491" y2="660.963" gradientUnits="userSpaceOnUse"><stop stop-color="#FAECE9"/><stop offset="1" stop-color="#FAF5F4" stop-opacity="0"/></linearGradient></defs></svg>
  </div>
  <div class="container mx-auto">
    <div class="flex items-center h-screen text-4xl text-gray-900 md:text-5xl lg:text-7xl about">
      <div class="relative z-30 lg:w-8/12">
        <p class="pb-8 font-semibold">
          <span class="text-4xl lg:text-6xl"><span class="waving-hand">👋</span></span>
          Ciao! I'm 
            <span 
              @mouseover="toggleImageVisibility" 
              @mouseleave="toggleImageVisibility" 
              class="transition duration-200 border-b-2 border-gray-500 border-dotted link hover:border-transparent">
                Silvia,
            </span>
        </p>
        <p class="font-normal">
          a Product Designer <br>
          based in Italy.
        </p>
      </div>
      <transition name="fade" class="opacity-0 lg:opacity-100">
        <div v-if="isImageDisplayed" class="absolute top-1/4 right-96">
          <img 
            src="@/assets/img/pics.gif" 
            alt="silvia"
            class="rounded-full w-80 h-80">
        </div>
      </transition>
    </div>
    <p class="pb-16 text-5xl font-semibold">My recent work</p>
    <div class="grid grid-cols-2 gap-16 grid-rows-auto">
        <div v-for="(project, index) in projects" :key="index">
          <router-link :to="project.path" class="link" >
            <img :src="`/assets/img/CaseStudies${project.image}`" :alt="project.name">
          </router-link>
          <div class="pt-4">
            <span class="small-text">{{project.cat}}</span>
            <p class="text-4xl font-semibold">{{project.name}}</p>
            <p class="text-2xl font-normal">{{project.description}}</p>
          </div>
        </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import Footer from '@/components/Footer.vue';
import CustomCursor from '@/components/CustomCursor.vue';

export default {
  name: "Home",
  data() {
    return {
      isImageDisplayed: false,
      scrollIns: null,
      projects: [
        {
          name: 'UOK',
          description: 'App for students\' wellbeing',
          image: '/UOK/case-study-uok.png',
          cat: 'UX/UI Design',
          path: '/work/uok'
        },
        // {
        //   name: 'Homely',
        //   description: 'Do It Yourself (DIY) tasks App for Designflows',
        //   image: '/Homely/case-study-homely.png',
        //   cat: 'UX/UI Design',
        //   path: ''
        // },
        // {
        //   name: 'Kiyomi',
        //   description: 'Chinese & Japanise Restaurant website',
        //   image: 'case-study-kiyomi.png',
        //   cat: 'UI Design'
        // },
        // {
        //   name: 'Frontend Developer Portfolio',
        //   description: 'One-page portfolio for a frontend developer',
        //   image: 'case-study-filippo-portfolio.png',
        //   cat: 'UI Design'
        // },
        // {
        //   name: 'iTown - Dashboard & Mobile App',
        //   description: 'Connect residents and tourists to local events, shops and more ',
        //   image: 'case-study-itown.png',
        //   cat: 'UX/UI Design'
        // }
      ]
    }
  },
  methods: {
    toggleImageVisibility() {
      this.isImageDisplayed = !this.isImageDisplayed
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo("#circle", {
        yPercent: 0,
      }, 
      {
        yPercent: 100,
        scrollTrigger: {
          trigger: "#circle",
          start: "top top",
          // end: "top 100vh",
          // markers: true,
          scrub: true,
          ease: 'power2.easeOut'
        }
      }
    );
  },
  components: {
    Footer,
    CustomCursor,
  }
}
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
</style>