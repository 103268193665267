import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      scrollPos: {
          top: 0,
          left: 0,
      },
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/play",
    name: "Playground",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Playground.vue"),
  },
  {
    path: "/work/uok",
    name: "UOK",
    component: () =>
      import("../views/work/UOK.vue"),
  }
];

const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition ||
    {
      top: to.meta?.scrollTop || 0,
      left: 0
    }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

// when changing page, don't preserve scroll position
router.beforeEach((to, from, next) => {
  from.meta?.scrollTop && (from.meta.scrollTop = window.scrollY)
  return next()
})

export default router;
